import React, { useEffect, useState } from 'react'

import { Col, Drawer, Menu, Row, Spin } from 'antd'
import {
  HomeOutlined,
  UserOutlined,
  LeftOutlined
} from '@ant-design/icons'

import './index.scss'

import { useHistory, useParams } from 'react-router-dom'
import { useLocation, Prompt } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setDrawerActivities, setNavigation } from '../../Store/Actions'
import { SvgInline } from '../SvgInline/SvgInline'

type TitleAppProps = {
  title: string | null
  goBack?: boolean
  showFooter?: boolean
  goBackUrl?: string
  marginBottom?: number
  hideMenu?: boolean
  goToOne?: boolean
}

type TitleProps = TitleAppProps | null

type RightIconProps = {
  icon: JSX.Element
  onClick: () => void
}

const AppFrame = ({ children: ChildrenComponent, isMobile }: any) => {
  const { pathname } = useLocation()
  const [title, setTitle] = useState<TitleProps>()

  const { blockNavigation, activities, drawerActivities } = useSelector(
    (state: any) => state
  )
  let [, currentPath] = pathname.split('/')

  const [showFooter, setShowFooter] = useState<boolean>(true)
  const [rightIcon, setRightIcon] = useState<RightIconProps>()
  const [hideMenu, setHideMenu] = useState<boolean>(false)

  const history = useHistory()
  const location = useLocation()
  const params = useParams<any>()

  const dispatch = useDispatch()

  const TitleApp: Map<string, TitleProps> = new Map([
    [
      '/dashboard',
      {
        title: isMobile ? null : 'DBTCHILE: Pacientes',
        goBack: false,
        showFooter: true
      }
    ],
    [
      '/configuration',
      {
        title: 'Configuración de Perfil',
        goBack: true,
        goBackUrl: '/dashboard',
        showFooter: false
      }
    ],
    [
      '/configuration/password',
      {
        title: 'Cambiar Contraseña',
        goBack: true,
        goBackUrl: '/configuration',
        showFooter: false
      }
    ],
    [
      '/patient/register',
      {
        title: 'Registrar Paciente',
        goBack: true,
        goBackUrl: '/dashboard',
        showFooter: false
      }
    ],
    [
      '/patient/list',
      {
        title: 'Listado de Pacientes',
        goBack: true,
        goBackUrl: '/dashboard',
        showFooter: false
      }
    ],
    [
      '/patient/view',
      {
        title: 'Ficha individual',
        goBack: true,
        // @ts-ignore
        goBackUrl: '/patient/list',
        showFooter: false
      }
    ],
    [
      '/patient/activity',
      {
        title: 'Registro de Actividad',
        goBack: true,
        // goBackUrl: '/patient/list',
        showFooter: false,
        marginBottom: 0
      }
    ],
    [
      '/location/ocupation',
      {
        title: 'CENSO de Pacientes',
        goBack: true,
        goBackUrl: '/dashboard',
        showFooter: false,
        marginBottom: 0,
        hideMenu: true,
        goToOne: true
      }
    ]
  ])

  useEffect(() => {
    const _path = `/${pathname.substring(1).split('/').slice(0, 2).join('/')}`

    if (!!TitleApp.get(_path)) {
      setTitle(TitleApp.get(_path))
      setShowFooter(TitleApp.get(_path)!.showFooter!)
      setHideMenu(TitleApp.get(_path)!.hideMenu || false)
    } else {
      setTitle(null)
      setShowFooter(true)
      setHideMenu(false)
    }

    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome, Firefox, IE and Opera
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  useEffect(() => {
    console.log({ title })
  }, [title])

  const isDark = false

  const mainMenu = [
    {
      key: 'home:index',
      name: 'Inicio',
      icon: <HomeOutlined />,
      disabled: false,
      locationKey: '/dashboard'
    },
    // {
    //   key: 'home:activities',
    //   name: 'Actividades',
    //   icon: <HeartFilled />,
    //   featured: true,
    //   disabled: true,
    //   locationKey: '/no-location-key'
    // },
    {
      key: 'user:profile',
      name: 'Perfil',
      icon: <UserOutlined />,
      disabled: false,
      locationKey: '/configuration'
    }
  ]

  const [, setSubMenu] = useState([])
  const [loading, setLoading] = useState(true)

  const handleMainMenu = ({ key }: any) => {
    // Sub-menu de perfil de usuario
    switch (key) {
      case 'home:index':
        return history.push('/dashboard')
      case 'home:activities':
        return dispatch(setDrawerActivities(true))
      case 'user:profile':
        return history.push('/configuration')
    }
  }

  React.useEffect(() => {
    const current = pathname.split('/')
    if (current.includes('caca') || current.includes('orderControl')) {
      dispatch(setNavigation(true))
    } else {
      if (blockNavigation) {
        dispatch(setNavigation(false))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  /* <div className={`AppFrame${isMobile ? ' isMobile' : ' isDesktop'}`}> */
  return (
    <div
      className={`${
        isMobile ? 'MobileAppFrame isMobile' : 'AppFrame isDesktop'
      }`}
    >
      <Prompt
        when={blockNavigation}
        message={
          '¿Estás seguro de perder los cambios? Recuerda publicar antes de abandonar esta página...'
        }
      />

      <Drawer
        className='DrawerActivities'
        placement={'bottom'}
        closable={false}
        onClose={() => dispatch(setDrawerActivities(false))}
        visible={drawerActivities}
        key={'BottomActivityDrawer'}
        height={'75%'}
      >
        <Row className='TitleDrawer'>
          <Col xs={24} md={24}>
            <h1>Registro de Actividades</h1>
            <h4>Selecciona una actividad para comenzar.</h4>
            <br />
          </Col>
        </Row>

        {/* {console.log(activities)} */}

        <Row className='ActivitySelector'>
          {activities?.map(
            ({
              id,
              name,
              description,
              icon
            }: // printable,
            // sortOrder
            any) => (
              <Col xs={8} md={8} key={`Activity__${id}`}>
                <div
                  className='Activity'
                  style={{
                    cursor: 'pointer'
                  }}
                  onClick={() => {
                    dispatch(setDrawerActivities(false))
                    history.push(`/patient/activity/${id}`)
                  }}
                >
                  <div className='ActivityIcon'>
                    {/* <img src={icon} alt={description} /> */}
                    <SvgInline url={icon} />
                  </div>
                  <div className='ActivityName'>
                    <h3>{name}</h3>
                  </div>
                </div>
              </Col>
            )
          )}
        </Row>
      </Drawer>

      {((isMobile && showFooter) || !isMobile) && (
        <div className={`Header${isDark ? ' Header-dark' : ' Header-light'}`}>
          <Menu
            selectedKeys={[currentPath]}
            mode='horizontal'
            theme={isDark ? 'dark' : 'light'}
            onClick={handleMainMenu}
            className='RootMenu'
            triggerSubMenuAction='click'
            style={{
              display: hideMenu ? 'none' : undefined
            }}
          >
            {mainMenu.map(
              ({ key, icon, name, disabled, featured, locationKey }: any) => (
                <Menu.Item
                  key={key}
                  disabled={disabled}
                  className={`
                ${
                  featured
                    ? `BottomMenuItem BottomMenuItem--Featured`
                    : 'BottomMenuItem'
                }
                ${locationKey === pathname ? ' ant-menu-item-active' : ''}
              `}
                >
                  {icon!}
                  <span className='Text'>{name}</span>
                </Menu.Item>
              )
            )}
          </Menu>
        </div>
      )}
      <Spin
        spinning={loading}
        delay={500}
        tip='Obteniendo información...'
        wrapperClassName='MainAppContent'
      >
        {React.cloneElement(ChildrenComponent, {
          exposeSubMenu: setSubMenu,
          setMainLoading: setLoading,
          setRightIcon: setRightIcon
        })}
      </Spin>
      {title?.title && (
        <div
          className='TitleApp'
          key={`Margin${title?.marginBottom}${isMobile ? 1 : 0}`}
          style={{
            marginBottom:
              typeof title?.marginBottom === 'undefined'
                ? 16
                : title?.marginBottom
          }}
        >
          {title?.goBack && (
            <LeftOutlined
              className='TitleApp__GoBack'
              onClick={() => {
                const _locationId = Number(params?.locationId || 0)
                // @ts-ignore
                if ((title?.goToOne && !location?.state?.goBackUrl) || _locationId === 1) {
                  if (_locationId === 1) {
                    // @ts-ignore
                    history.push('/dashboard')
                    // if (location?.state?.goBackUrl) {
                    //   // @ts-ignore
                    //   history.push(location?.state?.goBackUrl)
                    // } else {
                    //   if (title?.goBackUrl) {
                    //     history.push(title.goBackUrl)
                    //   } else {
                    //     history.goBack()
                    //   }
                    // }
                  } else {
                    history.goBack()
                  }
                } else {
                  // @ts-ignore
                  if (location?.state?.goBackUrl) {
                    // @ts-ignore
                    history.push(location?.state?.goBackUrl)
                  } else {
                    if (title?.goBackUrl) {
                      history.push(title.goBackUrl)
                    } else {
                      history.goBack()
                    }
                  }
                }
              }}
            />
          )}
          <span
            className={`TitleApp__Title${
              !!title?.goBack ? '' : ' TitleApp__Title__WithoutLeft'
            }`}
          >
            {title?.title}
          </span>
          {rightIcon?.icon && (
            <div onClick={rightIcon?.onClick} className='TitleApp__RightIcon'>
              {rightIcon?.icon}
            </div>
          )}
        </div>
      )}
    </div>
  )
}
export default AppFrame
