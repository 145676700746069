import { gql } from '@apollo/client'

export const PATIENT_RELEASE = gql`
  mutation PatientRelease($PatientReleaseInput: PatientReleaseInput!) {
    PatientRelease(input: $PatientReleaseInput) {
      success
      state
    }
  }
`

export const PATIENT_CONTACTS = gql`
query PatientGetContacts($PatientGetContactsInput: PatientGetContactsInput!) {
  PatientGetContacts(input: $PatientGetContactsInput) {
    success
    state 
		withPhone{
			name
			link
			relation
			formatted
		}
		withWhatsApp{
			name
			link
			relation
			formatted
		}
  }
}
`

export const PATIENT_VIEW = gql`
  query PatientView($PatientViewInput: PatientViewInput!) {
    PatientView(input: $PatientViewInput) {
      success
      state
      multipleGuardians
      Patient {
        id
        comments
        statusId
        statusImage
        Activities {
          id
          createdAt
          Activity {
            id
            name
            description
            icon
          }
          Authorizing {
            id
            firstName
            lastName
            rut
          }
          Answers {
            id
            ActivityField {
              id
              name
              description
              typeValue
              typeData
            }
            answer
          }
        }
        Person {
          id
          firstName
          lastName
          surName
          rut
          socialName
          birthDay
          age
        }
        PatientLocation {
          ingressDate
          releaseDate
          DefReleaseEvent {
            id
            name
          }
          Location {
            id
            name
            code
          }
        }
        PatientGuardian {
					Relationship {
						name
					}
          Guardian {
            firstName
            lastName
            surName
            socialName
            Contact {
              id
              phone
              isWhatsApp
              mail
            }
          }
        }
      }
    }
  }
`
