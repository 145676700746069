import React from 'react'
import { Helmet } from 'react-helmet'

import { Provider } from 'react-redux'
import configureStore from './Store'
import { PersistGate } from 'redux-persist/integration/react'

import { BrowserRouter, Switch } from 'react-router-dom'
import { ConfigProvider } from 'antd'

import './App.scss'

// Vistas
import {
  Login,
  DashboardIndex,
  ConfigurationIndex
} from './Views'

// Router
import { PublicRoute, PrivateRoute } from './Routes'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { API_URL } from './Constants'

// import firebase from 'firebase'
import moment from 'moment';
import 'moment/locale/es';
import locale from 'antd/lib/locale/es_ES';


import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import es from './I18n/es'
import RegisterPatient from './Views/RegisterPatient'
import PatientList from './Views/PatientList'
import PatientView from './Views/PatientView'
import PatientActivity from './Views/PatientActivity'
import LocationOcupation from './Views/LocationOcupation'
import PasswordUpdate from './Views/PasswordUpdate'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      es
    },
    lng: 'es', // if you're using a language detector, do not define the lng option
    fallbackLng: 'es',
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  })

moment.locale('es', {
    week: {
        dow: 1
    }
})

const { persistor, store } = configureStore()

const client = new ApolloClient({
  uri: API_URL,
  cache: new InMemoryCache()
});


function App() {
  // https://www.freecodecamp.org/news/how-to-add-push-notifications-to-a-web-app-with-firebase-528a702e13e1/
  /* const messaging = firebase.messaging()
  messaging.requestPermission().then(() => {
    messaging.getToken().then(token => {
      console.log({ token })
    })
  })
  */

  return <ApolloProvider client={client}><ConfigProvider locale={locale}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Helmet titleTemplate="%s" />
            <Switch>
              <PublicRoute restricted={true} component={Login} path="/" exact />
              <PrivateRoute component={Login} path="/secret" exact />

              <PrivateRoute component={DashboardIndex} path="/dashboard" exact />

              <PrivateRoute component={RegisterPatient} path="/patient/register" exact />
              <PrivateRoute component={PatientList} path="/patient/list" exact />
              <PrivateRoute component={PatientView} path="/patient/view/:patientId" exact />
              <PrivateRoute component={PatientActivity} path="/patient/activity/:activityId" exact />
              
              <PrivateRoute component={LocationOcupation} path="/location/ocupation/:locationId" exact />

              <PrivateRoute component={ConfigurationIndex} path="/configuration" exact />
              <PrivateRoute component={PasswordUpdate} path="/configuration/password" exact />
            </Switch>
          </BrowserRouter>
        </PersistGate>
      </Provider>
      </ConfigProvider>
    </ApolloProvider>
}

export default App