export const serverUrl = 'https://app.bodegaenlinea.cl/api/'
const forceProd = false
export const API_URL =
  forceProd || process.env.NODE_ENV === 'production'
    ? 'https://dbtpacientesapi.alnus.cl/graphql'
    : 'http://localhost:8080/graphql'
export const SERVER_URL =
  forceProd || process.env.NODE_ENV === 'production'
    ? 'https://dbtpacientesapi.alnus.cl/graphql'
    : 'http://localhost:8080'
export const UPLOAD_URL =
  forceProd || process.env.NODE_ENV === 'production'
    ? 'https://dbtpacientesapi.alnus.cl/graphql'
    : 'http://localhost:8080/mtdupload'
